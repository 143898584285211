export function Power() {
  return (
    <svg
      fill="none"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      className="stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M17.5473 6.72351C18.7008 7.8774 19.4863 9.34741 19.8044 10.9477C20.1225 12.548 19.959 14.2066 19.3345 15.7139C18.71 17.2213 17.6525 18.5096 16.2959 19.416C14.9392 20.3224 13.3443 20.8062 11.7127 20.8062C10.0811 20.8062 8.48618 20.3224 7.12954 19.416C5.77289 18.5096 4.71547 17.2213 4.09096 15.7139C3.46646 14.2066 3.30293 12.548 3.62104 10.9477C3.93915 9.34741 4.72461 7.8774 5.87813 6.72351"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7173 2.47017V11.6368"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
