import { ReactNode, PropsWithChildren } from 'react'
import Link from 'next/link'
import {
  useDefaultObservable,
  useStateFromUiEvents,
  uiEvents,
  useDefaultObservableAsRef,
  makeDefaultObservable,
} from '@/utils/event'
import { tap, mergeMapTo, delay } from 'rxjs/operators'
import { useVenueContext } from '@/services/venue'
import { useObservable } from 'rxjs-hooks'
import { merge, of } from 'rxjs'
import format from 'date-fns/format'
import { useAppContext } from '@/pages/_app'
import { FreshChatButton } from './freshchat'
import { truly, tryCatch } from 'shared/utils/types'
import { JivositeButton } from './jivosite'
import trim from 'lodash/trim'
import { TimeTable } from '@/components/time-table'
import subDays from 'date-fns/subDays'
import { TrixContent } from '@/components/trix-content'

declare global {
  interface AppUIEventMap {
    SidebarToggleClick: {
      type: 'SidebarToggleClick'
    }
    SidebarItemClick: {
      type: 'SidebarItemClick'
    }
    SidebarCloseAreaClick: {
      type: 'SidebarCloseAreaClick'
    }
  }
}

export type SidebarProps = {
  items: SidebarItem[]
}

export type SidebarItem = {
  title: string
  icon: ReactNode
  action:
    | {
        type: 'link'
        href: string
      }
    | {
        type: 'onClick'
        onClick: () => void
      }
}

const animationDuration = 0.2
export function Sidebar(props: SidebarProps) {
  const { t } = useAppContext()
  const { venue, admin, locale, orderManager, billing } = useVenueContext()!

  const sidebar = useSidebar()
  const sidebarIsOpen = useDefaultObservable(sidebar.isOpen$)

  const animationInProgress = useObservable(() => {
    return sidebar.isOpen$.pipe(
      mergeMapTo(merge(of(true), of(false).pipe(delay(animationDuration * 1000)))),
    )
  }, false)

  return (
    <div className={`w-64-animated ${sidebarIsOpen ? 'open' : ''} flex-shrink-0 overflow-hidden`}>
      <style jsx>{`
        .w-64-animated {
          width: 0px;
          height: 0px;
          transition: width ${animationDuration}s ease;
        }
        .w-64-animated.open {
          width: 256px;
          height: auto;
        }
      `}</style>
      <div style={{ width: sidebarIsOpen || animationInProgress ? '256px' : '0px' }}>
        {props.items.map((item, idx) => {
          const isLastItem = idx === props.items.length - 1
          const ghostBottomBorder = 'border-b border-ghost dark:border-ghostDark'

          const contents = (
            <div
              className={`flex items-center text-lg h-16 cursor-pointer ${
                isLastItem ? ghostBottomBorder : ''
              }`}
            >
              <div className="flex justify-center" style={{ width: '3.5rem' }}>
                <div className="relative" style={{ left: '2px' }}>
                  {item.icon}
                </div>
              </div>
              <div
                className={`h-full flex items-center w-full mt-1 leading-tight ${
                  !isLastItem ? ghostBottomBorder : ''
                }`}
              >
                {item.title}
              </div>
            </div>
          )
          const { action } = item
          switch (action.type) {
            case 'link':
              return (
                <Link key={idx} href={action.href} legacyBehavior prefetch={false}>
                  <a
                    onClick={() => {
                      uiEvents.next({
                        type: 'SidebarItemClick',
                      })
                    }}
                  >
                    {contents}
                  </a>
                </Link>
              )
            case 'onClick':
              return (
                <div
                  key={idx}
                  onClick={() => {
                    uiEvents.next({
                      type: 'SidebarItemClick',
                    })
                    return action.onClick()
                  }}
                >
                  {contents}
                </div>
              )
          }
        })}

        {(() => {
          if (!admin.isAdminView) {
            return null
          }

          const subscriptionStatus = billing.subscription.status
          if (subscriptionStatus.type !== 'active' && subscriptionStatus.type !== 'trial') {
            return null
          }

          const now = new Date()

          const periodEnd = new Date(subscriptionStatus.currentPeriod.end)
          const periodLastDay = subDays(periodEnd, 1)
          if (subscriptionStatus.type === 'trial') {
            return (
              <div className="px-4 py-5">
                {t('sidebar:trialTill')}{' '}
                {format(periodLastDay, 'eeee, d MMMM', {
                  locale,
                })}{' '}
                ({t('common:inclusive')})
              </div>
            )
          }

          const billingType = (
            billing.subscription.billingClientParams as { billing: 'cp' | 'manual' }
          ).billing

          if (subscriptionStatus.type === 'active' && now > periodLastDay) {
            switch (billingType) {
              case 'cp':
                return (
                  <div className="px-4 py-5">
                    {t('common:nextPayment')}{' '}
                    {format(periodEnd, 'eeee, d MMMM', {
                      locale,
                    })}
                  </div>
                )
            }

            return (
              <div className="px-4 py-5">
                {t('sidebar:paidTill')}{' '}
                {format(periodLastDay, 'eeee, d MMMM', {
                  locale,
                })}{' '}
                ({t('common:inclusive')})
              </div>
            )
          }

          return null
        })()}

        <div className="px-4 whitespace-pre-line">
          {venue.features.contactDetails?.address && (
            <p className="mt-4">
              <b>{t('common:address')}</b>
              <br />
              {venue.features.contactDetails.address}
            </p>
          )}
          {(() => {
            const openHoursText = (() => {
              const currentWorkSchdedule = venue.features.workSchedule?.find((x) => {
                return x.orderModes.includes(orderManager.orderMode.value)
              })

              if (currentWorkSchdedule) {
                return <TimeTable workSchedule={currentWorkSchdedule} dayNameFormat="short" />
              }

              const legacyText = venue.features.contactDetails?.openingHours

              if (legacyText) {
                return legacyText
              }

              return null
            })()

            if (!openHoursText) {
              return null
            }

            return (
              <div className="mt-4">
                <b>{t(`common:openHoursFor.${orderManager.orderMode.value}`)}</b>
                <br />
                {openHoursText}
              </div>
            )
          })()}

          {venue.features.contactDetails?.instagramUrl && (
            <SocialLink
              url={venue.features.contactDetails.instagramUrl}
              baseUrl="https://instagram.com"
              label="Instagram"
            />
          )}

          {venue.features.contactDetails?.facebookUrl && (
            <SocialLink
              url={venue.features.contactDetails.facebookUrl}
              baseUrl="https://facebook.com"
              label="Facebook"
            />
          )}

          {venue.features.contactDetails?.vkUrl && (
            <SocialLink
              url={venue.features.contactDetails.vkUrl}
              baseUrl="https://vk.com"
              label="VK"
            />
          )}

          {venue.features.contactDetails?.telegramUrl && (
            <SocialLink
              url={venue.features.contactDetails.telegramUrl}
              baseUrl="https://t.me"
              label="Telegram"
            />
          )}

          {venue.features.contactDetails?.tiktokUrl && (
            <SocialLink
              url={venue.features.contactDetails.tiktokUrl}
              baseUrl="https://tiktok.com"
              label="Tiktok"
            />
          )}

          {venue.features.contactDetails?.phone && (
            <p className="mt-4">
              <b>{t('common:phone')}</b>
              <br />
              <a className="underline" href={`tel:${venue.features.contactDetails.phone}`}>
                {venue.features.contactDetails.phone}
              </a>
            </p>
          )}
        </div>

        {venue.features.sidebarInfoHtml && (
          <TrixContent className="px-4 py-5" unescapedHtml={venue.features.sidebarInfoHtml} />
        )}

        {venue.features.freshChatToken && admin.isAdmin && (
          <div className="mt-4 px-4 py-5 mb-12">
            <FreshChatButton />
          </div>
        )}

        {venue.features.jivositeToken && admin.isAdmin && (
          <div className="mt-4 px-4 py-5 mb-12">
            <JivositeButton />
          </div>
        )}
      </div>
    </div>
  )
}

export type SocialLinkProps = {
  url: string
  baseUrl: string
  label: string
}

export function SocialLink(props: SocialLinkProps) {
  const parsedUrl = parseSocialUrl(props.url, props.baseUrl)
  if (!parsedUrl) {
    return null
  }

  return (
    <p className="mt-4">
      <b>{props.label}</b>
      <br />
      <a className="underline" href={parsedUrl.urlWithProtocol} target="_blank" rel="noreferrer">
        {parsedUrl.firstUrlSegment}
      </a>
    </p>
  )
}

export function SidebarToggle(props: PropsWithChildren<unknown>) {
  return (
    <div
      onClick={() => {
        return uiEvents.next({
          type: 'SidebarToggleClick',
        })
      }}
    >
      {props.children}
    </div>
  )
}

export function SidebarCloseArea(props: { children: (onClick: () => void) => ReactNode }) {
  const sidebar = useSidebar()
  const sidebarIsOpen = useDefaultObservableAsRef(sidebar.isOpen$)
  const animationInProgress = useDefaultObservableAsRef(sidebar.animationInProgress$)

  const onClick = () => {
    if (!sidebarIsOpen.current || animationInProgress.current) {
      return
    }
    uiEvents.next({
      type: 'SidebarCloseAreaClick',
    })
  }

  return <>{props.children(onClick)}</>
}

export function useSidebar() {
  const isOpen$ = useStateFromUiEvents({
    id: 'sidebarOpen',
    startingValue: false,
    process: (source, scan) => {
      return source.pipe(
        scan((isOpen, event) => {
          switch (event.type) {
            case 'SidebarToggleClick':
              return !isOpen
            case 'SidebarCloseAreaClick':
              return false
            case 'SidebarItemClick':
              return false
            default:
              return isOpen
          }
        }),
        tap((isOpen) => {
          const layout = document.getElementById('layout')
          if (isOpen) {
            layout?.classList.add('overflow-hidden')
            return
          }
          setTimeout(() => {
            const layout = document.getElementById('layout')
            return layout?.classList.remove('overflow-hidden')
          }, 200)
        }),
      )
    },
  })

  const animationInProgress$ = makeDefaultObservable(
    false,
    isOpen$.pipe(mergeMapTo(merge(of(true), of(false).pipe(delay(animationDuration * 1000))))),
  )

  return {
    isOpen$,
    animationInProgress$,
  }
}

function parseSocialUrl(url: string, base: string) {
  if (!url) {
    return null
  }

  const urlWithProtocol = url.startsWith('http') ? url : `https://${url}`

  const urlObject = tryCatch(() => {
    return new URL(urlWithProtocol)
  })

  if (urlObject instanceof Error) {
    return null
  }

  const urlSegments = urlObject.pathname.split('/').filter(truly)
  const firstUrlSegment = urlSegments[0]
  const firstUrlSegmentDecoded = tryCatch(() => {
    if (!firstUrlSegment) {
      return null
    }
    return decodeURIComponent(firstUrlSegment)
  })

  if (firstUrlSegmentDecoded instanceof Error) {
    return null
  }

  if (!firstUrlSegment) {
    const urlNormalized = trim(url, '@')
    const urlFromFirstFragment = tryCatch(() => {
      return new URL(urlNormalized, base)
    })

    if (!(urlFromFirstFragment instanceof Error)) {
      return {
        urlWithProtocol: urlFromFirstFragment.toString(),
        firstUrlSegment: urlNormalized,
      } as const
    }

    return null
  }

  return { urlWithProtocol, firstUrlSegment: firstUrlSegmentDecoded } as const
}
