import { useRef, useEffect } from 'react'

export function useDraggableContainer() {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const isDraggingRef = useRef(false)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }
    const container = containerRef.current
    // eslint-disable-next-line immutable/no-mutation
    container.style.cursor = 'grab'

    // eslint-disable-next-line immutable/no-let
    let pos = { top: 0, left: 0, x: 0, y: 0 }

    const mouseMoveHandler = function (e: MouseEvent) {
      // eslint-disable-next-line immutable/no-mutation
      isDraggingRef.current = true

      const dx = e.clientX - pos.x
      const dy = e.clientY - pos.y

      // eslint-disable-next-line immutable/no-mutation
      container.scrollTop = pos.top - dy
      // eslint-disable-next-line immutable/no-mutation
      container.scrollLeft = pos.left - dx
    }

    const mouseDownHandler = function (e: MouseEvent) {
      // eslint-disable-next-line immutable/no-mutation
      container.style.cursor = 'grabbing'
      // eslint-disable-next-line immutable/no-mutation
      container.style.userSelect = 'none'
      pos = {
        left: container.scrollLeft,
        top: container.scrollTop,
        x: e.clientX,
        y: e.clientY,
      }

      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }

    const mouseUpHandler = function () {
      setTimeout(() => {
        // eslint-disable-next-line immutable/no-mutation
        isDraggingRef.current = false
      }, 1)
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)

      // eslint-disable-next-line immutable/no-mutation
      container.style.cursor = 'grab'
      container.style.removeProperty('user-select')
    }

    containerRef.current.addEventListener('mousedown', mouseDownHandler)
    return () => {
      return containerRef.current?.removeEventListener('mousedown', mouseDownHandler)
    }
  }, [containerRef.current])

  return {
    containerRef,
    isDraggingRef,
  }
}
