import { VenueFeatures, VenueServiceBrand } from './types/api'

export type ServiceBrand = {
  title: string
  domainName: string
  lpOriginDomainName: string
  contactEmail: string
  offersFreeTier: boolean
  initialVenueFeatures: VenueFeatures
  prototypeOrderNotifier: {
    getFromExistingWithName: string
  }
  mailjetContactListId: string
  poweredBy: {
    url: string
  }
  telegramBotName: string
  googleAnalytics: {
    trackingId: string
  }
}

export const serviceBrands: { [key in VenueServiceBrand]: ServiceBrand } = {
  foodeon: {
    title: 'Foodeon',
    domainName: 'foodeon.com',
    lpOriginDomainName: 'fdncom.tilda.ws',
    contactEmail: 'hello@foodeon.com',
    offersFreeTier: false,
    initialVenueFeatures: {
      jivositeToken: 'aBJmP5dm8A',
      flatware: { defaultQuantity: 0 },
    },
    prototypeOrderNotifier: {
      getFromExistingWithName: 'demo',
    },
    mailjetContactListId: '39934',
    poweredBy: {
      url: 'https://foodeon.com?utm_source=foodeon_menu&utm_medium=referral&utm_content=admin_sidebar_button',
    },
    telegramBotName: 'FoodeonBot',
    googleAnalytics: {
      trackingId: 'UA-158048577-1',
    },
  },
  foodba: {
    title: 'Foodba',
    domainName: 'foodba.com',
    lpOriginDomainName: 'foodba.tilda.ws',
    contactEmail: 'hello@foodba.com',
    offersFreeTier: true,
    initialVenueFeatures: {
      freshChatToken: '87ea269f-910f-4ed4-8073-4ac71502910c',
      productImagePlaceholderVisibility: 'hidden',
    },
    prototypeOrderNotifier: {
      getFromExistingWithName: 'demo-en-telegram-notifier',
    },
    mailjetContactListId: '43040',
    poweredBy: {
      url: 'https://foodba.com?utm_source=foodba_menu&utm_medium=referral&utm_content=admin_sidebar_button',
    },
    telegramBotName: 'FoodbaComBot',
    googleAnalytics: {
      trackingId: 'G-FD0TFF9T9Y',
    },
  },
}
