import { useEffect, useRef } from 'react'
import { Cross } from './cross'
import { useVenueContext } from '@/services/venue'
import { uiEvents } from '@/utils/event'
import { delay } from 'shared/utils/async'
import { useAppContext } from '@/pages/_app'
import { venueGetOrderingTypes } from 'shared/venue'

declare global {
  interface AppUIEventMap {
    OrderModeOverlayClick: {
      type: 'OrderModeOverlayClick'
    }
  }
}

export function OrderModeOverlay() {
  const { t } = useAppContext()
  const venueContext = useVenueContext()!
  const { venue, orderManager } = venueContext
  const orderingTypes = venueGetOrderingTypes(venue.features)
  const availableOrderingTypes = Object.keys(orderingTypes || {}).filter((k) => {
    return k !== 'default'
  })
  const hasMultipleOrderTypes = availableOrderingTypes.length > 1
  const currentOrderMode = orderManager.orderMode

  const orderModeOverlayRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ;(async () => {
      const shouldShowOverlay = hasMultipleOrderTypes && !currentOrderMode.isChosenByUser

      if (shouldShowOverlay) {
        // pushing the calculation back to avoid a subtle race condition
        // otherwise, it results in a bug due to miscalculations
        //
        // dirty but it works
        await delay(100)
      }

      const element = orderModeOverlayRef.current
      const orderModeSelector = document.getElementById('order-mode-selector')
      if (!element || !orderModeSelector) {
        return
      }

      const menuColumn = document.getElementById('menu-column')!
      const menuColumnBorderLeft = Number(
        getComputedStyle(menuColumn).borderLeft.split(' ')[0]!.slice(0, -2),
      )

      // eslint-disable-next-line immutable/no-mutation
      element.style.width = `${menuColumn.clientWidth}px`
      // eslint-disable-next-line immutable/no-mutation
      element.style.marginLeft = `${menuColumnBorderLeft}px`
      // eslint-disable-next-line immutable/no-mutation
      element.style.height = `${orderModeSelector.offsetTop + 20}px`

      // eslint-disable-next-line immutable/no-mutation
      element.style.display = shouldShowOverlay ? 'block' : 'none'
    })()
  }, [venue, currentOrderMode.isChosenByUser])

  return (
    <div className="relative">
      <div
        ref={orderModeOverlayRef}
        className="absolute hidden inset-0 z-10"
        style={{
          background: 'rgba(0, 0, 0, 0.8)',
        }}
        onClick={() => {
          uiEvents.next({
            type: 'OrderModeOverlayClick',
          })
        }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex justify-end px-5 mt-5">
            <div className="cursor-pointer">
              <Cross />
            </div>
          </div>
          <div className="pb-10 px-5">
            <div className="text-back dark:text-primaryDark text-2xl text-center leading-tight">
              {t('orderModeOverlay:chooseOrderMode')}
              <br />
              <br />
            </div>
            <div className="flex justify-center pr-4">
              <svg
                width="51"
                height="58"
                viewBox="0 0 51 58"
                className="fill-back dark:fill-primaryDark"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M34.4143 54.4142C33.6333 55.1953 32.3669 55.1953 31.5859 54.4142L18.858 41.6863C18.0769 40.9052 18.0769 39.6389 18.858 38.8579C19.639 38.0768 20.9053 38.0768 21.6864 38.8579L33.0001 50.1716L44.3138 38.8579C45.0949 38.0768 46.3612 38.0768 47.1422 38.8579C47.9233 39.6389 47.9233 40.9052 47.1422 41.6863L34.4143 54.4142ZM21.3862 1.05832C35.0996 14.2441 35.0001 29.1827 35.0001 53L31.0001 53C31.0001 28.8173 30.9006 15.7559 18.6138 3.94166L21.3862 1.05832Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
