import { useAppContext } from '@/pages/_app'
import { useVenueContext } from '@/services/venue'
import { daysOfWeek, localizeTimeString } from '@/utils/date'
import { rotate } from 'shared/utils/array'
import getISODay from 'date-fns/getISODay'
import { capitalize } from 'lodash'
import { VenueWorkSchedule } from 'shared/types/api'

export type TimeTableProps = {
  workSchedule: VenueWorkSchedule
  dayNameFormat: 'wide' | 'short'
}

export function TimeTable(props: TimeTableProps) {
  const { t } = useAppContext()
  const { locale } = useVenueContext()!

  const weekRotationValue = 1 - (locale.options?.weekStartsOn ?? 1)
  const localizedDaysOfWeek = rotate(daysOfWeek, -weekRotationValue)
  const localizedDays = rotate(
    daysOfWeek.map((_dayOfWeek, i) => {
      return capitalize(locale.localize!.day(i, { width: props.dayNameFormat }))
    }),
    1 - weekRotationValue,
  )

  return (
    <div>
      {localizedDaysOfWeek.map((dayOfWeek, index) => {
        const schedule = props.workSchedule.timeTable[dayOfWeek]
        const isToday = index === getISODay(new Date()) - 1

        return (
          <div key={dayOfWeek} className={`flex justify-between ${isToday ? 'font-bold' : ''}`}>
            <div>{localizedDays[index]}</div>
            <div>
              {(() => {
                if (schedule === 'closed') {
                  return t('common:closed').toLowerCase()
                }

                if (!schedule || (schedule.opensAt === '00:00' && schedule.closesAt === '00:00')) {
                  return t('common:open24').toLowerCase()
                }

                return `${localizeTimeString(schedule.opensAt)} – ${localizeTimeString(
                  schedule.closesAt,
                )}`
              })()}
            </div>
          </div>
        )
      })}
    </div>
  )
}
