export function PictureIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      className="stroke-primary dark:stroke-primaryDark"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="svg_1"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        d="m18.9175,2.8988l-14.00002,0c-1.10457,0 -2,0.89543 -2,2l0,14c0,1.1046 0.89543,2 2,2l14.00002,0c1.1046,0 2,-0.8954 2,-2l0,-14c0,-1.10457 -0.8954,-2 -2,-2z"
      />
      <path
        id="svg_2"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        d="m8.41748,9.8988c0.82842,0 1.50002,-0.6716 1.50002,-1.5c0,-0.82842 -0.6716,-1.5 -1.50002,-1.5c-0.82843,0 -1.5,0.67158 -1.5,1.5c0,0.8284 0.67157,1.5 1.5,1.5z"
      />
      <path
        id="svg_3"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        d="m20.9175,14.8988l-5,-5l-11.00002,11"
      />
    </svg>
  )
}
