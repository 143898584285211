import { useAppContext } from '@/pages/_app'
import { uploadVenueImage, useVenueContext } from '@/services/venue'
import { filterEvents, uiEvents } from '@/utils/event'
import { webAppMobileWidth } from 'shared/misc'
import { isAllowedImageType } from 'shared/product'
import React, { PropsWithChildren, useRef } from 'react'
import { filter, map } from 'rxjs/operators'
import { AdminProductButton } from './admin-product-button'
import { PictureIcon } from './picture-icon'
import { Spinner } from './spinner'
import { useCropper } from './cropper'
import { LanguageSwitcher } from './language-switcher'
import { keys, unique } from 'shared/utils/array'

declare global {
  interface AppUIEventMap {
    HeaderImageSubmit: {
      type: 'HeaderImageSubmit'
      file: File
    }
  }
}

export type HeaderProps = PropsWithChildren<{
  fullImageUrl: string
}>
export function Header(props: HeaderProps) {
  const { apiClient } = useAppContext()
  const venueContext = useVenueContext()!
  const { admin, venueName, venue } = venueContext
  const imageRef = useRef<HTMLInputElement>(null)

  const cropperId = 'headerImage'

  const cropper = useCropper({
    cropperId,
    aspectRatio: webAppMobileWidth / 160,
    input$: uiEvents.pipe(
      filterEvents(['HeaderImageSubmit']),
      map((event) => {
        return event.file
      }),
      filter(isAllowedImageType),
    ),
    async uploadImage(event) {
      await uploadVenueImage(venueName, event.file, event.cropBox, apiClient)
    },
  })

  const supportedLanguages = unique([venue.language, ...keys(venue.features.languageConfig ?? {})])

  return (
    <div
      className="relative w-full"
      style={{
        aspectRatio: `${webAppMobileWidth} / 160`,
      }}
      onDragOver={(event) => {
        event.stopPropagation()
        event.preventDefault()
      }}
      onDrop={async (event) => {
        if (!admin.isAdminView) {
          return
        }
        if (cropper.isImageUploading) {
          return
        }
        event.stopPropagation()
        event.preventDefault()
        const files = event.dataTransfer.files
        if (!files) {
          return
        }
        const file = files[0]

        if (!file) {
          return
        }

        uiEvents.next({
          type: 'HeaderImageSubmit',
          file,
        })
      }}
    >
      {cropper.isImageUploading && (
        <>
          <div className="z-10 flex justify-center items-center absolute w-full h-full">
            <div className="w-16">
              <Spinner />
            </div>
          </div>
          <div
            className="z-10 absolute w-full h-full rounded-lg"
            style={{
              background: 'white',
              opacity: 0.25,
            }}
          ></div>
        </>
      )}

      <input
        ref={imageRef}
        type="file"
        className="hidden"
        onChange={async () => {
          const file = imageRef.current!.files?.[0]
          if (!file) {
            return
          }
          if (cropper.isImageUploading) {
            return
          }

          // we need to clear out the input because the onChange won't get triggered if we
          // submit the same file as previously (because the input doesn't "change" in that case)
          // eslint-disable-next-line immutable/no-mutation
          imageRef.current!.value = ''
          uiEvents.next({
            type: 'HeaderImageSubmit',
            file,
          })
        }}
      />

      <div className="absolute flex" style={{ top: '20px', right: '20px' }}>
        {supportedLanguages.length > 1 && <LanguageSwitcher />}
        {admin.isAdminView && (
          <div className="ml-3">
            <AdminProductButton
              onClick={() => {
                imageRef.current!.click()
              }}
            >
              <PictureIcon />
            </AdminProductButton>
          </div>
        )}
      </div>

      <div
        className="h-full bg-center bg-cover"
        style={{ backgroundImage: `url(${props.fullImageUrl})` }}
      >
        {props.children}
      </div>
    </div>
  )
}
