import { DOMAttributes, PropsWithChildren } from 'react'

export type AdminProductButtonProps = PropsWithChildren<{
  onClick?: DOMAttributes<HTMLDivElement>['onClick']
}>
export function AdminProductButton(props: AdminProductButtonProps) {
  return (
    <div
      onClick={props.onClick}
      className="flex items-center justify-center border border-accent rounded-lg cursor-pointer bg-back dark:bg-backDark"
      style={{
        width: '53px',
        height: '35px',
      }}
    >
      {props.children}
    </div>
  )
}
