export function Cross() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      className="stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.5" cy="20.5" r="20.5" className="fill-back dark:fill-backDark" stroke="none" />
      <path d="M28 13L13 28" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 13L28 28" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
