import React, { PropsWithChildren, useEffect, useState } from 'react'
import { uiEvents } from '@/utils/event'
import { CrossSm } from './cross-sm'

export type ModalMenuProps = PropsWithChildren<{
  id: string
  title: string
}>

declare global {
  interface AppUIEventMap {
    ModalMenuBackClick: {
      type: 'ModalMenuBackClick'
      modalMenuId: string
    }
  }
}

export function ModalMenu(props: ModalMenuProps) {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    const layout = document.getElementById('layout')!
    setWidth(layout.offsetWidth)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line immutable/no-mutation
    document.body.style.overflow = 'hidden'
    return () => {
      // eslint-disable-next-line immutable/no-mutation
      document.body.style.overflow = 'initial'
    }
  }, [])

  function closeModalMenu() {
    uiEvents.next({ type: 'ModalMenuBackClick', modalMenuId: props.id })
  }

  useEffect(() => {
    function closeModalOnEsc(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        closeModalMenu()
      }
    }
    document.addEventListener('keydown', closeModalOnEsc)

    return () => {
      document.removeEventListener('keydown', closeModalOnEsc)
    }
  }, [])

  if (!width) {
    return null
  }

  const marginPx = 40

  return (
    <div className="fixed left-0 top-0 w-full z-50 text-lg">
      <div
        className="mx-auto flex justify-center items-center relative h-screen overflow-auto bg-primary bg-opacity-80 pb-40"
        style={{
          width,
        }}
        onClick={closeModalMenu}
      >
        <div
          className="mx-auto mt-10 rounded-lg max-w-webAppMobileWidth"
          style={{
            width: width - marginPx,
          }}
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <div className="flex justify-between items-center px-5 py-4 bg-backOpaque dark:bg-backOpaqueDark rounded-t-lg">
            <div>{props.title}</div>
            <button className="focus:outline-none ml-2" onClick={closeModalMenu}>
              <CrossSm />
            </button>
          </div>
          <div className="bg-back dark:bg-backDark rounded-b-lg">{props.children}</div>
        </div>
      </div>
    </div>
  )
}
