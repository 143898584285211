import { useVenueContext } from '@/services/venue'
import { CSSProperties, ReactNode } from 'react'

export type VasButtonProps = {
  caption: string
  icon: ReactNode
  type: string
  shrinkCaption: boolean
}

export function VasButton(props: VasButtonProps) {
  const { venue } = useVenueContext()!
  const customStyle = (venue.features.vas?.buttonStyle as CSSProperties) ?? {}

  const hasCustomStyle = Object.keys(customStyle).length > 0

  const bgColor = (() => {
    switch (props.type) {
      case 'bookTable':
        return 'bg-[#FFE4CB] dark:bg-[#4f2500]'
      case 'callWaiter':
        return 'bg-[#def4ff] dark:bg-[#003f5e]'
      case 'feedback':
        return 'bg-[#FFF0A0] dark:bg-[#594c00]'
      default:
        return ''
    }
  })()

  return (
    <div
      className={`${
        !hasCustomStyle ? bgColor : ''
      }  p-3 h-full w-full flex justify-center items-center rounded-lg leading-tight`}
      style={{
        ...customStyle,
      }}
    >
      <div style={props.shrinkCaption ? { width: '70px' } : {}}>{props.caption}</div>
      <div className="ml-2 flex-shrink-0">{props.icon}</div>
    </div>
  )
}
