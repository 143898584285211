import { useAppContext } from '@/pages/_app'
import { uiEvents } from '@/utils/event'

declare global {
  interface AppUIEventMap {
    LanguageSwitcherClick: {
      type: 'LanguageSwitcherClick'
    }
  }
}

export function LanguageSwitcher() {
  const { i18n } = useAppContext()

  const currentLanguage = i18n.language

  return (
    <button
      onClick={() => {
        uiEvents.next({
          type: 'LanguageSwitcherClick',
        })
      }}
      className="flex items-center justify-center bg-back dark:bg-backDark rounded-lg cursor-pointer focus:outline-none"
      style={{
        width: '57px',
        height: '35px',
      }}
    >
      <img src={`/img/language/${currentLanguage}.svg`} style={{ width: 30 }} />
      <svg
        className="ml-1 stroke-primary dark:stroke-primaryDark"
        width="8"
        height="4"
        viewBox="0 0 8 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path opacity="0.5" d="M0 0L4 4L8 0H0Z" fill="black" />
      </svg>
    </button>
  )
}
