export function AlertCircle() {
  return (
    <svg
      fill="none"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6024 18.3332 10C18.3332 5.39763 14.6022 1.66667 9.99984 1.66667C5.39746 1.66667 1.6665 5.39763 1.6665 10C1.6665 14.6024 5.39746 18.3333 9.99984 18.3333Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10 6.66667V10" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M10 13.3333H10.0083"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
