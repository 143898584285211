import Head from 'next/head'

export type TrixContentProps = {
  className?: string
  unescapedHtml: string
}

export function TrixContent(props: TrixContentProps) {
  return (
    <>
      <TrixStyles />
      <div
        className={`trix-content ${props.className ?? ''}`}
        dangerouslySetInnerHTML={{ __html: props.unescapedHtml }}
      ></div>
    </>
  )
}

export function TrixStyles() {
  return (
    <Head>
      <style key="trix-custom-styles" global>{`
      .trix-content a {
        text-decoration: underline;
      }
      
      .trix-content h1 {
        font-weight: 500;
        display: block;
      }
      
      .trix-content ul {
        list-style-type: disc;
      }
      
      .trix-content ol {
        list-style: auto;
      }
    `}</style>
    </Head>
  )
}
