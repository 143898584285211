export type TitleProps = {
  title: string
}

export function Title(props: TitleProps) {
  // \u00a0 is the &nbsp; char to keep the height of normal text
  const text = props.title
  return (
    <h1 className="font-bold text-left text-2xl leading-tight my-4 px-5 bg-back dark:bg-backDark dark:text-primaryAccentDark">
      {text}
    </h1>
  )
}
