import { windowStorage } from '@/utils/env'
import { useStateFromUiEvents, useDefaultObservable, uiEvents } from '@/utils/event'
import { join } from 'shared/utils/array'
import React from 'react'
import { ModalMenu } from './modal-menu'
import { useObservable } from 'rxjs-hooks'
import { from } from 'rxjs'
const languagesPromise = import('../languages.json').then((m) => {
  return m.default
})

declare global {
  interface AppUIEventMap {
    LanguageSwitcherClick: {
      type: 'LanguageSwitcherClick'
    }
    LanguageModalMenuChooseLanguage: {
      type: 'LanguageModalMenuChooseLanguage'
      chosenLanguage: string
    }
  }
}

export type LanguageModalMenuProps = {
  languages: string[]
}

export function LanguageModalMenu(props: LanguageModalMenuProps) {
  const showLanguageSwitcher$ = useStateFromUiEvents<boolean>({
    id: 'showLanguageSwitcher',
    startingValue: false,
    storage: windowStorage,
    process: (source, scan) => {
      return source.pipe(
        scan((state, event) => {
          switch (event.type) {
            case 'LanguageSwitcherClick':
              return true
            case 'LanguageModalMenuChooseLanguage':
              return false
            case 'ModalMenuBackClick':
              return event.modalMenuId === 'languageSwitcher' ? false : state
          }
          return state
        }),
      )
    },
  })
  const showLanguageSwitcher = useDefaultObservable(showLanguageSwitcher$)

  const languages = useObservable(() => {
    return from(languagesPromise)
  })

  if (!showLanguageSwitcher || !languages) {
    // preload flag icons
    return (
      <div className="w-0 h-0 inline-block">
        {props.languages.map((languageCode) => {
          return <img key={languageCode} src={`/img/language/${languageCode}.svg`} />
        })}
      </div>
    )
  }

  return (
    <ModalMenu id="languageSwitcher" title="">
      <div>
        {join(
          props.languages.map((languageCode) => {
            return (
              <button
                key={languageCode}
                className="flex w-full items-center justify-between p-5 focus:outline-none"
                onClick={() => {
                  uiEvents.next({
                    type: 'LanguageModalMenuChooseLanguage',
                    chosenLanguage: languageCode,
                  })
                }}
              >
                <span>{languages[languageCode as keyof typeof languages].native}</span>
                <img src={`/img/language/${languageCode}.svg`} style={{ width: 37 }} />
              </button>
            )
          }),
          (idx) => {
            return <div key={idx} className="h-px bg-backOpaque dark:bg-backOpaqueDark"></div>
          },
        )}
      </div>
    </ModalMenu>
  )
}
