import React from 'react'
import { BackArrowIcon } from './back'
import Link from 'next/link'

export type HeadPanelProps = {
  url?: string
  onClick?: () => void
  text: string
}
export function HeadPanel(props: HeadPanelProps) {
  return (
    <div className="sticky z-30 top-0 h-16 bg-back dark:bg-backDark flex items-center font-bold text-2xl leading-tight py-4 px-5 border-b border-ghost dark:border-ghostDark">
      {props.url && (
        <Link href={props.url}>
          <BackArrowIcon color="fill-primary dark:fill-primaryAccentDark" />
        </Link>
      )}
      {props.onClick && (
        <span className="cursor-pointer" onClick={props.onClick}>
          <BackArrowIcon color="fill-primary dark:fill-primaryDark" />
        </span>
      )}
      <h1 className="ml-3 leading-none dark:text-primaryAccentDark">{props.text}</h1>
    </div>
  )
}
